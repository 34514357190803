import React, { useState } from 'react';
import AccountHeader from '../../components/AccountHeader';
import { getAddressMP } from '../../components/Mpurse';
import { MpurseStateInfo} from '../../components/MpurseState';
import ModalWindow from '../../components/ModalWindow'
import { Link } from 'gatsby';
import "../../style/styles.css"

const Kojiki = ({ children }) => {
  const [addr, setAddr] = useState("");
  const isBrowser = typeof window !== "undefined"
  try {
    getAddressMP().then((r) => {
      if(r === "") {
        if (isBrowser) {
          window.location.href = '/account';
        }
        return;
      } else {
        setAddr(r);
      }
    });
  }
  catch{};
  
  if(addr != "") {
    return (
      <AccountHeader>
        <MpurseStateInfo />
        <div className="px-4 md:px-32">
          <div className="w-full h-full py-12 md:py-24 mx-auto flex justify-center items-center">
            <div className='w-full'>
              <div className="text-xl md:text-3xl mx-auto flex justify-center items-center">
                {/* <ModalWindow xmp="50" type="mp" /> */}
                <ModalWindow xmp="10" type="mp" />
              </div>
            </div>
          </div>
        </div>
      </AccountHeader>
    );
  }
  if(addr == "") {
    return (
      <AccountHeader>
        <div className="px-4 md:px-32">
          <div className="w-full h-full py-12 md:py-24 mx-auto flex justify-center items-center">
            <div className='w-full'>
              <div className="text-xl md:text-3xl mx-auto flex justify-center items-center">
                <div className="mt-16 mb-2 px-2 lg:px-48 text-2xl md:text-4xl font-bold text-center">
                  <p>Please enable Mpurse.</p>
                  <Link to="/mpurse" className="mt-4 mb-4 px-12 md:pb-2 rounded-lg border-2 border-solid w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>Mpurse?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccountHeader>
    );
  }
}
export default Kojiki;